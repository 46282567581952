import React from 'react';
import { graphql } from 'gatsby';
import Sign from '../components/sign'
import Layout from "../components/layout"
import {
  Section,
  Content,
} from "bloomer"  

export default ({ data }) => {
  //const post = data.markdownRemark;
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
        <article>
          <header className="content">          
            {/* <Sign icon={frontmatter.mname} title={frontmatter.name}></Sign> */}
            <h1 className="title is-2">{frontmatter.name}</h1>
            <h2 className="subtitle is-5">{frontmatter.dates}</h2>
            <div><strong>Regente:</strong> {frontmatter.regente}</div>
            <div><strong>Cor:</strong> {frontmatter.cor}</div>
            <div><strong>Elemento:</strong> {frontmatter.elemento}</div> 
          </header>
          <Content dangerouslySetInnerHTML={{ __html: html }}/>
          </article>
    </Layout> 
  );

};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        name
        dates
        regente
        cor
        elemento
        mname
      }
      html
    }
  }
`;